import $ from 'jquery';

const OHFormJS = {
  $form: null,
  $token: null,
  $verify: null,
  $submit: null,
  $message: null,
  $error: null,
  submitting: false,
  setToken: function (token) {
    this.$token.val(token);
  },
  submitForm: function () {
    if (this.submitting) {
      return;
    }

    this.submitting = true;
    this.$submit.prop('disabled', true);
    this.$error.html('');

    $.ajax({
      url: this.$form.attr('action'),
      dataType: 'json',
      data: this.$form.serialize(),
      type: 'post',
      success: (data) => {
        if (data.success) {
          this.$error.html('');
          window.location.replace(data.redirect);
        } else if (data.error) {
          this.$error.html(data.message);
        }
      },
      error: () => {
        this.$error.html('Oops! Something unexpected happened.');
      },
      complete: () => {
        this.$submit.prop('disabled', false);
        this.submitting = false;
      },
    });
  },
  validate: function (callback) {
    this.$submit.prop('disabled', true);

    let valid = true;

    valid &= OHFormJS.Client.valid();
    valid &= OHFormJS.Email.valid();
    valid &= OHFormJS.Invoices.valid();

    this.$submit.prop('disabled', !valid);
  },
  init: function (key) {
    this.$form = $('#payment-form');
    this.$token = $('#payment_form_stripe_token');
    this.$verify = $('#verify');
    this.$submit = $('#submit');
    this.$message = $('#verify-message');
    this.$error = $('#form-error');

    OHFormJS.Client.init();
    OHFormJS.Email.init();
    OHFormJS.Invoices.init();
    OHFormJS.Stripe.init(key);

    this.$submit
      .on('click', (e) => {
        e.preventDefault();

        // stripe valid returns a promise
        const stripe = OHFormJS.Stripe.createToken();
        stripe.then((valid) => {
          if (valid) {
            this.submitForm();
          }
        });
      })
      .prop('disabled', true);

    this.validate();
  },
};

OHFormJS.Client = {
  $input: $('#payment_form_client'),
  valid: function () {
    return '' !== this.$input.val();
  },
  init: function () {
    this.$input.on('input', () => {
      this.$input.toggleClass('error', !this.valid());
    });

    this.$input.on('change', () => {
      OHFormJS.validate();
    });
  },
};

OHFormJS.Email = {
  $input: $('#payment_form_email'),
  valid: function () {
    return '' !== this.$input.val();
  },
  init: function () {
    this.$input.on('input', () => {
      this.$input.toggleClass('error', !this.valid());
    });

    this.$input.on('change', () => {
      OHFormJS.validate();
    });
  },
};

OHFormJS.Invoices = {
  $container: $('#invoices').find('tbody'),
  $button: $('#add-invoice'),
  $buttonRow: null,
  total: 0,
  $total: $('#invoice-total'),
  invoices: {},
  addInvoice: function () {
    const prototype = this.$container.data('prototype');

    let index = this.$container.data('index');

    const invoice = prototype.replace(/__name__/g, index);

    this.$container.data('index', index + 1);

    const $invoice = $(invoice);

    this.$buttonRow.before($invoice);

    this.addInvoiceData(index, $invoice);

    OHFormJS.validate();
  },
  addInvoiceData: function (index, $invoice) {
    $invoice.data('index', index);
    this.invoices[index] = new OHFormJS.Invoices.Invoice($invoice);

    this.addInvoiceDelete($invoice);
  },
  addInvoiceDelete: function ($invoice) {
    const $delete = $invoice.find('.remove-invoice');

    $delete.on('click', (e) => {
      e.preventDefault();

      delete this.invoices[$invoice.data('index')];

      $invoice.remove();

      this.valid();
      this.updateTotal();

      if (0 === $('.invoice').length) {
        this.addInvoice();
      }

      OHFormJS.validate();
    });
  },
  updateTotal: function () {
    this.total = 0;

    $.each(this.invoices, (i, invoice) => {
      this.total += invoice.amount();
    });

    this.total = this.total.toFixed(2);

    this.$total.html(this.total);
  },
  valid: function () {
    let valid = true;

    $.each(this.invoices, (i, invoice) => {
      valid &= invoice.valid();
    });

    return valid;
  },
  init: function () {
    this.$buttonRow = this.$button.closest('tr');

    this.$container.data('index', $('.invoice').length);

    this.$button.on('click', (e) => {
      e.preventDefault();

      this.addInvoice();
    });

    $('.invoice').each((index, invoice) => {
      this.addInvoiceData(index, $(invoice));
    });

    this.updateTotal();

    OHFormJS.validate();
  },
};

OHFormJS.Invoices.Invoice = function ($invoice) {
  const number = {
    $el: $invoice.find('.number').find('input'),
    valid: function () {
      return '' !== this.$el.val();
    },
  };

  number.$el.on('input', function () {
    number.$el.val(number.$el.val().replace('#', ''));

    number.$el.toggleClass('error', !number.valid());
  });

  number.$el.on('change', function () {
    OHFormJS.validate();
  });

  const amount = {
    $el: $invoice.find('.amount').find('input'),
    value: function () {
      const value = this.$el.val() * 1.0;

      return isNaN(value) ? 0 : value;
    },
    valid: function () {
      return '' !== this.$el.val() && 0 < this.value();
    },
  };

  amount.$el.on('input', function () {
    amount.$el.toggleClass('error', !amount.valid());

    OHFormJS.Invoices.updateTotal();
  });

  amount.$el.on('change', function () {
    OHFormJS.validate();
  });

  const inv = {};

  inv.valid = function () {
    let valid = true;

    valid &= number.valid();
    valid &= amount.valid();

    return valid;
  };

  inv.amount = function () {
    return amount.value();
  };

  return inv;
};

OHFormJS.Stripe = {
  stripe: null,
  elements: null,
  card: null,
  $error: $('#card-error'),
  setError: function (msg) {
    this.$error.html(msg);
  },
  clearError: function () {
    this.setError('');
  },
  init: function (key) {
    this.stripe = Stripe(key);
    this.elements = this.stripe.elements();

    // Custom styling can be passed to options when creating an Element.
    const style = {
      base: {
        color: '#484848',
        lineHeight: '24px',
        fontFamily: 'Arial',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#ad412a',
        iconColor: '#ad412a',
      },
    };

    // Create an instance of the card Element
    this.card = this.elements.create('card', {
      hidePostalCode: true,
      style: style,
    });

    // Add an instance of the card Element into the `card-element` <div>
    this.card.mount('#card-element');

    this.card.addEventListener('change', (e) => {
      if (e.error) {
        this.setError(e.error.message);
      } else {
        this.clearError();
      }
    });
  },
  createToken: function () {
    const promise = this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        this.setError(result.error.message);
        return false;
      } else {
        OHFormJS.setToken(result.token.id);
        this.clearError();
        return true;
      }
    });

    return promise;
  },
};

export default OHFormJS;
